<template>
  <div>
    <div>
      <futongtitle class="title" ref="productImage"> </futongtitle>
      <div class="information">
        <div class="information-box"></div>
        <div class="information-list">
          <div class="box">
            <div class="information-left">
              <div class="left">
                <div class="let">
                  <img class="imgs" src="../../../../img/yuang.png" alt="" />
                  <div class="let1">
                    <div class="name">胡铁柱</div>
                    <div class="jian">
                      <img
                        class="shiming"
                        src="../../../../img/shiming.png"
                        alt=""
                      />
                      <div class="shi">已实名</div>
                    </div>
                    <div class="jian">
                      <img
                        class="shiming"
                        src="../../../../img/shiming.png"
                        alt=""
                      />
                      <div class="shi">已实名</div>
                    </div>
                  </div>
                  <br />
                  <div>男 | 23岁 | 工龄3年 | 个人</div>
                  <div class="renyuan">搬运工/装卸工</div>
                </div>
                <div class="let-r">
                  <div class="btn">立即联系</div>
                  <br />
                  <div class="weiz">
                    <img class="gpss" src="../../../../img/gpss.png" alt="" />
                    <div class="zi">山西 · 晋城</div>
                  </div>
                  <br />
                  <div class="miao">
                    找晋城、临汾的工作，主要从事小工、装卸工相关的工作，做事认真负责，有活的老板请...
                  </div>
                </div>
              </div>
            </div>
            <div class="information-rit"></div>
          </div>
          <div class="information-left"></div>
        </div>
      </div>
      <footerss class="footer"></footerss>
    </div>
  </div>
</template>

<script>
import futongtitle from '../../../../components/hometitle/hometitle.vue'
import footerss from '../../../../components/foot/foot.vue'
export default {
  components: {
    futongtitle,
    footerss
  },
  data() {
    return {}
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {}
}
</script>

<style scoped lang="scss">
.miao {
  float: right;
  width: 400px;
  height: 21px;
  margin-top: 61px;
  color: rgba(153, 153, 153, 1);
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.information {
  width: 100%;
  height: 100%;
}

.information-box {
  width: 1200px;
  height: 375px;
  background: rgba(255, 255, 255, 1);
  border-radius: 3px;
  margin: 28px auto;
}

.information-list {
  width: 1200px;

  margin: 0px auto 15px;
  justify-content: space-between;
  .box {
    display: flex;

    justify-content: space-between;
  }
  .information-left {
    width: 901px;
    height: 150px;
    background: rgba(255, 255, 255, 1);
    border-radius: 3px;
    margin-bottom: 15px;

    .renyuan {
      width: 107px;
      height: 24px;
      background: rgba(227, 244, 255, 1);
      border-radius: 3px;
      margin-top: 25px;
      color: rgba(51, 133, 255, 1);
      font-size: 14px;
      text-align: center;
      line-height: 24px;
    }
    .left {
      display: flex;
      justify-content: space-between;
      // margin-top: 14px;
      padding-top: 14px;
      margin-left: 20px;
      .let {
        // display: flex;
      }
      .let-r {
        margin-right: 24px;
        .btn {
          float: right;
          width: 120px;
          text-align: center;
          line-height: 36px;
          height: 36px;
          background: rgba(21, 146, 234, 1);
          border-radius: 3px;
          color: rgba(255, 255, 255, 1);
          font-size: 16px;
        }
        .weiz {
          float: right;
          margin: 35px -117px 0px -51px;
          display: flex;
          justify-content: space-between;
          .gpss {
            width: 18px;
            height: 18px;
          }
          .zi {
            color: rgba(21, 146, 234, 1);
            font-size: 14px;
          }
        }
      }

      .imgs {
        width: 80px;
        height: 80px;
        display: block;
        margin-right: 17px;
        float: left;
      }
      .let1 {
        display: flex;
        margin-top: 5px;
        .name {
          color: rgba(68, 68, 68, 1);
          font-size: 18px;
          margin-top: 5px;
        }
        .jian {
          margin-top: 5px;
          display: flex;
          .shiming {
            width: 24px;
            height: 24px;
            margin-left: 15px;
          }
          .shi {
            color: rgba(255, 141, 25, 1);
            font-size: 12px;
            margin-left: 8px;
          }
        }
      }
    }
  }

  .information-rit {
    width: 282px;
    height: 130px;
    background: rgba(255, 255, 255, 1);
    border-radius: 3px;
  }
}
</style>